<template>
  <div class="exclusiveClients-view">
    <div class="d-flex justify-content-between flex-wrap-y align-items-center">
      <div class="operation-left">
        <el-button :disabled="multipleSelection.length == 0 " @click="dialogTableVisible = true,dialogType = '移至成员'">移至成员</el-button>
        <el-button :disabled="multipleSelection.length == 0 " @click="turnOverShare">移至共享</el-button>
        <!-- <el-button class="export">
          <div>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-save"></use>
            </svg>
            <span>导出</span>
          </div>
        </el-button> -->
      </div>
      <div class="operation-right d-flex">
        <el-input
          v-model="name"
          class="mr-16 d-flex align-items-center"
          placeholder="请输入电话、姓名或微信号"
        ></el-input>
        <el-input
          v-model="followName"
          class="mr-16 d-flex align-items-center"
          placeholder="请输入员工名称"
        ></el-input>
        <el-button style="height: 32px" type="primary" @click="queryClient">查询</el-button>
        <el-button style="height: 32px" type="primary" @click="dialogVisible = true">标签筛选</el-button>
      </div>
    </div>
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 @handleSelectionChange="handleSelectionChange"
                 :loading="loading"
                 :total="total"
                 @handleClickRow="handleClickRow"
                 >
      <template v-slot:table>
        <el-table-column type="selection" width="55" @click.stop=""/>
        <el-table-column prop="customerId" align="center" width="100" label="序号" />
        <el-table-column prop="name" width="150" align="center" label="姓名" />
        <el-table-column
          prop="phone"
          align="center"
          width="120"
          label="手机号码"
          show-overflow-tooltip
        />
        <el-table-column prop="email" align="center" label="邮箱" width="150" show-overflow-tooltip/>
        <el-table-column prop="personalWx" align="center" label="微信号"  width="150" show-overflow-tooltip/>
        <el-table-column prop="inputDate" align="center" label="录入时间" width="170" show-overflow-tooltip/>
        <el-table-column prop="lableName" align="center" label="标签">
          <template slot-scope="scope">
            <div class="flex-c-c flex-wrap-y h100p">
              <span :style="{background:item.lableColor,
                            color: item.lableColor !== '#F5F5F6' ? '#ffffff' : '#454A4C',}"
                    v-for="(item,index) in scope.row.lableModelList"
                    :key="index"
                    class="pl20 pr20 h30 lineheight30 d-block mb10 br5  mr10">
                    {{item.lableName}}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="turnoverAmount" width="120" align="center" label="成交金额">
          <template slot-scope="scope"
            ><span style="color: #fe8575">{{
             '￥'+ (scope.row.turnoverAmount / 100).toFixed(2)
            }}</span></template
          >
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template class="operating" slot-scope="scope">
            <el-button size="mini" type="text" @click="detall_tap(scope.row.cardId)">详情</el-button>
            <el-button size="mini" type="text" @click="edit_tap(scope.row.cardId)">编辑</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>

    <memberList :memberData="memberData"
                :title="dialogType"
                :dialogTableVisible="dialogTableVisible"
                :total="memberTotal"
                @dialogClose="dialogClose"
                @dialogCurrentChange="dialogCurrentChange"
                @turnOverMember="turnOverMember">
    </memberList>
    <labelFiltr :visible.sync="dialogVisible"
                @onConfirmTap="onConfirmTap"
                @resetTap="resetTap"
                @updateVisible="updateVisible"
                ></labelFiltr>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import memberList from "@/components/common/memberList";
import labelFiltr from "../components/labelFiltr"
import { getCustomerPC,getCompanyStaffList,moveCustomer } from "@/api/client";
export default {
  name: "exclusiveClients",
  components: {
    commonTable,
    memberList,
    labelFiltr
  },
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      customerIds:'',
      total:0, //总页数
      pageNum:1,
      pageSize:10,
      loading:false,
      name:'',//查询条件 ： 电话名称
      followName:'',//查询条件 ： 销售员姓名
      dialogTableVisible:false, //成员弹窗
      dialogType:'移至成员',// 弹出框类型
      dialogPageNum:'',
      memberData:[] ,//
      memberTotal: 0,
      dialogVisible:false,
      lableIds:[]
    };
  },
  created(){
    this.getClientList()
    this.getMember()
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.customerIds =  this.multipleSelection.map(item => item.customerId);
    },
    handleClickRow(row){
      this.$router.push('../../customerDetall?id=' + row.cardId)
    },
    getClientList() {
      this.loading = true
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        customerState: 3,
        type:1,
        name:this.name,
        followName:this.followName,
        lableIds:this.lableIds
      };
      getCustomerPC(data).then((res) => {
        this.loading = false
        this.tableData = res.data.pageInfo.list;
        this.tableData.forEach(v=>{
          v.selection = false
        })
        this.total = res.data.pageInfo.total;
      })
      .catch(err=>{
        this.tableData = []
        this.loading = false
      })
    },
    getMember(){
      let data = {
        pageNum: this.dialogPageNum,
        queryType:'web'
      }
        getCompanyStaffList(data)
        .then((res)=> {
            this.memberData = res.data.list;
            console.log(this.memberData)
            this.memberTotal = res.data.totalNumber
        });
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getClientList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getClientList()
    },
    //弹出框关闭
    dialogClose(){
      this.dialogTableVisible = false
    },
    //弹出框分页
    dialogCurrentChange(val){
      this.dialogPageNum = val
      this.getMember()
    },
    //确认人员
    turnOverMember(val){
      if(!val){
        this.$message({
          title: "警告",
          message: "请先选择移交的成员",
          type: "warning",
        });
      }

      let data ={
        customerIds:this.customerIds.join(','),
        ascriptionId:val,
        customerState: 1,
      }
      moveCustomer(data)
      .then(res =>{
        if(res.code == 200){
          this.$message({
            message: "移交成功",
            type: "success",
          });
          this.getClientList()
          this.dialogTableVisible = false
        }
      })
      .catch(err=>{
        if(err.code == 201){
          this.$message({
            message: err.message,
            type: "warning",
          });
          this.dialogTableVisible = false
        }
      })
    },
    turnOverShare(){
      this.$confirm( '此操作会将客户移至共享池' , "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        let data ={
          customerIds:this.customerIds.join(','),
          customerState: 2,
        }
        moveCustomer(data)
        .then(res =>{
          if(res.code == 200){
            this.$message({
              message: "移交成功",
              type: "success",
            });
            this.getClientList()
            this.dialogTableVisible = false
          }
        })
        .catch(err=>{
          if(err.code == 201){
          this.$message({
            message: err.message,
            type: "warning",
          });
          this.dialogTableVisible = false
        }
        })

      })
      .catch(() => {});
    },
    detall_tap(id){
      this.$router.push('../../customerDetall?id=' + id)
    },
    edit_tap(id){
      this.$router.push('../../customerInput?id=' + id)
    },
    onConfirmTap(arr){
      this.lableIds = arr
      this.dialogVisible = false
      this.pageNum = 1
      this.pageSize = 10
      this.getClientList()
    },
    resetTap(){
      this.lableIds = []
      this.dialogVisible = false
      this.pageNum = 1
      this.pageSize = 10
      this.getClientList()
    },
    updateVisible(val){
      this.dialogVisible = val
    },
    // 查询
    queryClient(){
      this.pageNum = 1
      this.getClientList()
    }
  },
};
</script>

<style lang="scss" scoped>
.mr-16 {
  margin-right: 16px;
}
.exclusiveClients-view {
  background: #ffffff;
  border-radius: 0px 0px 2px 2px;
  padding: 24px;
}
.operation-left {
  .el-button {
    width: 96px;
    height: 32px;
    background: #51cbcd;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    &:active {
      border-color: #51cbcd;
    }
  }
}
.operation-right {
  .el-button--primary {
    background: #51cbcd;
    border-color: #51cbcd;
  }
  .export {
    border: 1px solid rgba(81, 203, 205, 0.4);
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #51cbcd;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
</style>
